@import 'partials/variables';

.btn {
    height: 50px;
    min-width: 80px;
    padding: 0 15px;
    border: 1px solid $primary-1;
    border-radius: 3px;
    line-height: 34px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
    color: $white;
    background-color: $primary-1;

    &:focus,
    &.focus {
        box-shadow: none !important;
    }

    &:hover {
        opacity: 0.9;
        color: $white;
    }

    &:active {
        opacity: 0.8;
    }

    &.btn-primary {
        color: $white !important;
        background-color: $primary-1 !important;
        border-color: $primary-1 !important;
    }

    &.btn-secondary {
        color: $primary-1 !important;
        background-color: $white !important;
        border-color: $primary-1 !important;

        &:disabled,
        &.disabled {
            color: $grey-100 !important;
            background-color: $white !important;
            border-color: $grey-100 !important;
        }
    }

    &:disabled,
    &.disabled {
        color: $white !important;
        background-color: $grey-100 !important;
        border-color: $grey-100 !important;

        &:hover {
            box-shadow: none !important;
            cursor: default !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    .btn {
        border-radius: 0;
    }
}
