@import 'partials/variables';

* {
    font-family: $font-stack;
    font-weight: 300;
    font-size: 16px;
}

html {
    font-size: 16px; /*for using REM units*/
}

body {
    font-family: $font-stack;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.15;
    color: $black;
}

button {
    font-family: $robotoRegular;
    font-size: 18px;
}

h1,
.h1 {
    font-family: $robotoMedium;
    line-height: 30px;
    text-align: center;
    color: $black;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0;
}

h2,
.h2 {
    line-height: 30px;
    color: $black;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
}

h3,
.h3 {
    line-height: 30px;
    color: $black;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
}
