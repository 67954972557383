@import 'colors';
$robotoRegular: 'Roboto';
$robotoMedium: 'Roboto-Medium';
$robotoBold: 'Roboto-Bold';
$font-stack: $robotoRegular, sans-serif;

$primary-1: $turquoise-100;

$secondary-1: $black;

$danger: $red-200;

$text-color: $grey-700;

$background-color: $grey-100;
