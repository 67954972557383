$black: #000000;
$white: #ffffff;

$turquoise-100: #00525b;

$grey-100: #f0f0f0;
$grey-200: #eaebee;
$grey-300: #dbdbdb;
$grey-400: #cdcdcd;
$grey-500: #b6b6b6;
$grey-600: #b9b9b9;
$grey-700: #a6a6a6;
$grey-800: #787878;
$grey-focus: #7b7b7b;

$red-200: #b81111;
