@import 'partials/variables';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px $white inset;
}

.form-control,
.ui-inputtext {
    height: 50px;
    width: 100%;
    line-height: 29px;
    border: 1px solid $grey-400;
    border-radius: 3px;
    color: $black;
    font-size: 14px;
    letter-spacing: 0;
    transition: border-color 0s;

    &:focus {
        color: $black;
        box-shadow: none;
        border-color: $grey-focus;
    }

    &.error {
        border-color: $danger !important;
    }

    &:disabled {
        background-color: $white;
        border-color: $grey-200;
        color: $grey-200;
    }

    &::-webkit-input-placeholder {
        color: $grey-400;
    }
}
