@import 'partials/variables';

.radio {
    & > input[type='radio'] {
        display: none;

        &:checked {
            & + label:before {
                background: $secondary-1;
                border: 1px solid $secondary-1;
                border-width: 4px;
                border-color: $white;
            }

            & + label:after {
                border-color: $secondary-1;
            }
        }

        // Disabled state label.
        &:disabled + label {
            color: $grey-400;
            cursor: auto;
        }

        // Disabled box.
        &:disabled + label:before {
            opacity: 0.3;
        }
    }

    & > label {
        position: relative;
        height: 26px;
        margin: 0;
        padding-left: 25px !important;
        cursor: pointer;
        font-size: 16px;
        color: $black;
        line-height: 30px;
        letter-spacing: 0;

        &:before {
            content: '';
            background-color: $white;
            display: inline-block;
            height: 17px;
            width: 17px;
            left: 0;
            margin-right: 10px;
            position: absolute;
            border-radius: 99px;
            bottom: 2px;
        }

        &:after {
            content: '';
            border: 1px solid $grey-400;
            display: inline-block;
            height: 17px;
            width: 17px;
            left: 0;
            margin-right: 10px;
            position: absolute;
            border-radius: 99px;
            bottom: 2px;
        }
    }
}
