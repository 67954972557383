@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: auto;
    src: url(../assets/fonts/roboto-v20-latin-regular.eot?#iefix) format('embedded-opentype'); /* IE6-IE8 */
    src: url(../assets/fonts/roboto-v20-latin-regular.woff2) format('woff2'); /* Super Modern Browsers */
    src: url(../assets/fonts/roboto-v20-latin-regular.woff) format('woff'); /* Modern Browsers */
    src: url(../assets/fonts/roboto-v20-latin-regular.ttf) format('truetype'); /* Safari, Android, iOS */
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-display: auto;
    src: url(../assets/fonts/roboto-v20-latin-500.eot?#iefix) format('embedded-opentype'); /* IE6-IE8 */
    src: url(../assets/fonts/roboto-v20-latin-500.ttf) format('truetype'); /* Safari, Android, iOS */
    src: url(../assets/fonts/roboto-v20-latin-500.woff) format('woff'); /* Modern Browsers */
    src: url(../assets/fonts/roboto-v20-latin-500.woff2) format('woff2'); /* Super Modern Browsers */
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-display: auto;
    src: url(../assets/fonts/roboto-v20-latin-700.eot?#iefix) format('embedded-opentype'); /* IE6-IE8 */
    src: url(../assets/fonts/roboto-v20-latin-700.woff2) format('woff2'); /* Super Modern Browsers */
    src: url(../assets/fonts/roboto-v20-latin-700.woff) format('woff'); /* Modern Browsers */
    src: url(../assets/fonts/roboto-v20-latin-700.ttf) format('truetype'); /* Safari, Android, iOS */
    font-weight: 700;
}
